<template>
  <section class="product-line">
    <v-snackbar
      rounded
      :color="snackColor"
      v-model="showResult"
      :timeout="2000"
      top
    >
      {{ result }}
    </v-snackbar>
    <div class="messages">
      <h3 class="message">
        <span class="underline">Expertos</span> fabricantes de
        <span class="underline">juntas y vulcanizados técnicos </span> desde
        1972.
      </h3>
    </div>
    <div class="picks" v-if="this.productItems.length > 0">
      <h2 class="title">
        <span class="highlight"><b>Lista de Productos</b> </span>
      </h2>
      <!-- Sección de búsqueda y filtrado -->
      <div class="filter-search">
        <div class="search-bar">
          <input
            type="text"
            v-model="searchQuery"
            @input="filterProducts"
            placeholder="Buscar productos..."
            class="search-input"
          />
        </div>

        <div class="filters">
          <div class="filter-group">
            <label for="category" class="filter-label">Categoría</label>
            <select
              v-model="selectedCategory"
              @change="filterProducts"
              class="filter-select"
              id="category"
            >
              <option value="">Todas las categorías</option>
              <option
                v-for="category in categories"
                :key="category.attributes.nombre"
                :value="category.attributes.nombre"
              >
                {{ category.attributes.nombre }}
              </option>
            </select>
          </div>

          <div class="filter-group">
            <label for="price-range" class="filter-label"
              >Rango de precio</label
            >
            <input
              type="range"
              v-model="priceRange"
              min="0"
              max="1000"
              @input="filterProducts"
              class="filter-price"
              id="price-range"
            />
            <span class="price-value">{{ priceRange }}€</span>
          </div>
          <div class="filter-group-2 ordenar-direccion">
            <label class="filter-label">Ordenar</label>
            <div class="ordenar-container">
              <span class="sort-text">{{ sortText }}</span>
              <button
                class="button-flecha"
                id="boton-ordenar"
                @click="toggleSortDirection"
              >
                <span class="flecha">&#9650;</span>
              </button>
            </div>
          </div>

          <!-- Nueva opción de ordenación -->
        </div>
        <div
          v-if="filteredProductItems.length > 0"
          class="product-count"
          style="display: flex; align-items: center"
        >
          <p>{{ filteredProductItems.length }} producto(s) encontrado(s)</p>

          <!-- Botón para eliminar los filtros -->
          <button
            @click="resetFilters"
            style="
              margin-left: 10px;
              margin-bottom: 14px;
              background: none;
              border: none;
              cursor: pointer;
            "
          >
            <i class="mdi mdi-filter-remove" style="font-size: 1.5em"></i>
          </button>
        </div>
      </div>

      <div class="product-cards">
        <div
          class="card"
          v-for="prod in filteredProductItems"
          :key="prod.id"
          @click="this.goToProd(prod.id)"
        >
          <div class="card-img">
            <img
              :src="this.getImage(prod.attributes.avatar.data.attributes.url)"
              class="img-fluid"
              alt=""
            />
          </div>
          <div class="product-info">
            <h4 class="product-name">{{ prod.attributes.nombre }}</h4>
            <p
              class="price"
              v-if="!prod.attributes.medicion.includes('A consultar')"
            >
              <span>{{
                prod.attributes.precio.toFixed(2) +
                "" +
                prod.attributes.medicion
              }}</span>
            </p>
            <p class="price" v-else>
              <span>{{ prod.attributes.medicion }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="refurbished">
      <h2 class="title">
        <span class="highlight"><b>Entregas 24h</b> </span> con presupuestos
        personalizados
      </h2>
    </div>
    <div class="postal">
      <div class="presupuestos">
        <img src="@/assets/presupuestos.png" class="postal-img" alt="" />
        <span
          >Ofrecemos presupuestos <b>SIN compromiso</b>. Personaliza cada oferta
          a las <b>necesidades del cliente</b>, ofreciendo buen servicio a
          <b>precios competitivos</b>.</span
        >
      </div>
      <div class="clientes">
        <img src="@/assets/cliente-1.png" class="postal-img" alt="" />
        <span
          ><b>Asesoramos</b> en cada oferta y pedido gracias a nuestra
          <b>experiencia y profesionalidad</b>. Nos adaptamos para ofrecer en
          cada ocasión la mejor la solución.</span
        >
      </div>
      <div class="entregas24h">
        <img src="@/assets/envios24h.png" class="postal-img" alt="" />
        <span
          >Posibilidad de realizar <b>entregas en 24 horas</b> para el
          <b>territorio nacional</b>, gracias a nuestros proveedores
          logísticos.</span
        >
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["productItems", "baseBackUrl", "categories"]),
  },
  data() {
    return {
      snackColor: "error",
      showResult: false,
      result: "",
      selectedCategory: "",
      searchQuery: "",
      priceRange: 1000,
      // categories: ["Categoría 1", "Categoría 2", "Categoría 3"], // Agregar categorías reales
      filteredProductItems: this.productItems,
      direccionAscendente: true,
      sortText: "Al mayor precio",
    };
  },
  methods: {
    ...mapActions(["addCartItem", "removeCartItem", "removeAllCartItems"]),
    goToProd(id) {
      this.$router.push("/producto/" + id);
    },
    getImage(url) {
      return this.baseBackUrl + url;
    },
    addToCart(prod, ud) {
      this.addCartItem({ prod: prod, cantidad: ud });
      this.result = "Producto añadido al carro";
      this.snackColor = "success";
      this.showResult = true;
    },
    toggleSortDirection() {
      this.direccionAscendente = !this.direccionAscendente;
      this.sortText = this.direccionAscendente
        ? "Al mayor precio"
        : "Al menor precio";

      const flecha = document.querySelector(".flecha");
      flecha.innerHTML = this.direccionAscendente ? "&#9650;" : "&#9660;";
      this.filterProducts();
    },
    filterProducts() {
      let filtered = this.productItems.filter((prod) => {
        const matchesSearch = prod.attributes.nombre
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
        const matchesCategory = this.selectedCategory
          ? prod.attributes.categoria.data &&
            prod.attributes.categoria.data.attributes.nombre ===
              this.selectedCategory
          : true;
        const matchesPrice = prod.attributes.precio <= this.priceRange;

        return matchesSearch && matchesCategory && matchesPrice;
      });

      filtered.sort((a, b) => {
        if (this.direccionAscendente) {
          return b.attributes.precio - a.attributes.precio;
        } else {
          return a.attributes.precio - b.attributes.precio;
        }
      });

      this.filteredProductItems = filtered;
    },
    resetFilters() {
      this.selectedCategory = "";
      this.searchQuery = "";
      this.priceRange = 1000;

      this.filterProducts();
    },
  },
  mounted() {
    if (this.productItems && this.productItems.length > 0) {
      this.filterProducts();
    }
  },
  watch: {
    productItems() {
      this.filterProducts();
    },
  },
};
</script>

<style scoped>
.presupuestos,
.entregas24h,
.clientes {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
}

.postal-img {
  height: 8rem;
  padding: 1em;
}
.postal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.product-line {
  z-index: 0;
}
.messages {
  display: flex;
  align-items: center;
  justify-content: center;
}
.message {
  font-size: 1.3rem;
  margin: 0.4rem 0.5rem;
  padding: 0.3rem 3rem;
  background-color: #004283;
  color: white;
  text-align: center;
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
  border-radius: 2rem;
  box-shadow: 0 0 20px var(--blue);
}
.underline {
  text-decoration: underline;
  text-transform: uppercase;
}
h2 {
  color: #000;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  margin: 1rem;
}
.discounted .highlight {
  color: var(--blue);
}
h2::after {
  content: "";
  width: 100px;
  position: absolute;
  margin: 0 auto;
  height: 4px;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  bottom: -20px;
}
.carousel {
  margin: 3rem auto;
  padding: 0 2rem;
}

.carousel .carousel-item {
  min-height: 330px;
  text-align: center;
  overflow: hidden;
}
.carousel .carousel-item .img-box {
  height: 150px;
  width: 100%;
  position: relative;
}
.carousel .carousel-item img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 0.5rem;
}
.carousel .carousel-item h4 {
  font-size: 1rem;
}
.carousel .carousel-item .btn {
  border-radius: 2rem;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  background: none;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  margin-top: 5px;
  line-height: 16px;
}
.carousel .carousel-item .btn:hover,
.carousel .carousel-item .btn:focus {
  color: #fff;
  background: #000;
  border-color: #000;
  box-shadow: none;
}
.carousel .carousel-item .btn i {
  font-size: 1rem;
  font-weight: bold;
}
.carousel .thumb-wrapper {
  text-align: center;
}
.carousel .thumb-content {
  padding: 1rem;
}
.carousel-control-prev,
.carousel-control-next {
  height: 100px;
  width: 40px;
  margin: auto 0;
}
.carousel-control-prev i,
.carousel-control-next i {
  position: absolute;
  top: 50%;
  display: inline-block;
  z-index: 5;
  left: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.8);
}

.carousel .item-price {
  font-size: 12px;
  margin: 0;
}
.carousel .item-price span {
  color: #86bd57;
  font-size: 120%;
}
.carousel-indicators {
  bottom: -40px;
}
.list-inline {
  margin: 0;
}
.star-rating i {
  font-size: 0.5rem;
  color: var(--yellow);
  margin: 0;
  padding: 0;
}
.carousel .carousel-item .btn {
  color: var(--blue);
}
.row {
  display: flex;
  justify-content: space-around;
}
.col-sm-3 {
  width: 23%;
  height: 50%;
  cursor: pointer;
  transition: 0.4s;
}
.col-sm-3:hover {
  transform: scale(1.04);
}
.discounted,
.picks {
  background-color: var(--grey);
  padding: 1rem;
  margin: 1rem 0;
  border: white 8px outset;
}

.picks .highlight {
  color: var(--blue);
}
.product-cards {
  display: flex;
  /* padding: 2rem 0; */
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.card {
  box-shadow: 0 0 5px #00000040;
  transition: 0.4s;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 20%;
  height: 360px;
}
.card:hover {
  box-shadow: 0 0 10px #00000040;
  transform: scale(1.02);
}

.card-img {
  width: 200px;
  padding: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60%;
}
.card-img img {
  width: 70%;
}
.product-info {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: flex-start;
  height: 40%;
}
.product-name {
  padding: 0;
  margin: 0;
  font-size: 1.2rem;
}
.price {
  margin: 0;
  font-size: 0.8rem;
}
.price span {
  font-size: 110%;
  color: var(--green);
}
.stars {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}
.star {
  font-size: 0.8rem;
  color: var(--yellow);
}
.count {
  color: var(--blue);
  font-size: 0.7rem;
  padding-left: 5px;
}
.cart {
  text-decoration: none;
  color: var(--blue);
}
.refurbished .highlight {
  color: var(--blue);
}

@media screen and (max-width: 1000px) {
  .carousel .carousel-item .img-box {
    height: 90px;
  }
  .thumb-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .thumb-content h4 {
    font-size: 0.8rem;
  }
  .list-inline {
    display: none;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 1rem;
    width: 1rem;
  }
  .presupuestos,
  .entregas24h,
  .clientes {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
  }
}
@media screen and (max-width: 750px) {
  .card-img {
    height: 40%;
  }
  .product-info {
    height: 60%;
  }
  .carousel .item-price {
    font-size: 10px;
    margin: 0;
  }
  .carousel .carousel-item h4[data-v-a99e3fae] {
    font-size: 0.8rem;
  }
  .postal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }
}
@media screen and (max-width: 500px) {
  .picks {
    margin: 0;
    padding: 0;
  }
  .product-cards {
    flex-direction: column;
  }
  .card {
    width: 80%;
    justify-content: center;
    height: 165px;
  }
  .card-img {
    width: 40%;
    height: auto;
    padding: 0;
  }
  .product-info {
    width: 60%;
    padding-right: 0.5rem;
    height: auto;
  }
  .postal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }
}
.filter-search {
  /* background-color: #ffffff; */
  padding: 15px;
  border-radius: 2px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
  max-width: 900px;
  margin: 0 auto;
}

.search-bar {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  background-color: white;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.search-input:focus {
  border-color: #007bff;
  outline: none;
}

/* Contenedor de los filtros */
.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.filter-group {
  width: 37%;
  min-width: 220px;
  margin-bottom: 15px;
}
.filter-group-2 {
  width: 22%;
  margin-bottom: 15px;
}

.filter-label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: #555;
}

.button-flecha {
  background-color: #ffffff;
  border: 2px solid #ddd;
  border-radius: 8px;
  margin-left: 10px;
}

.filter-select,
.filter-price {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid #ddd;
  background-color: white;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.filter-select:focus,
.filter-price:focus {
  border-color: #007bff;
  outline: none;
}

/* Rango de precio */
.filter-price {
  -webkit-appearance: none;
  appearance: none;
  background-color: #ffffff;
  height: 8px;
  border-radius: 8px;
}

.filter-price::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007bff;
  cursor: pointer;
}

.filter-price::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007bff;
  cursor: pointer;
}

/* Muestra el valor del rango de precio */
.price-value {
  margin-top: 8px;
  font-size: 14px;
  color: #333;
}

/* Para pantallas pequeñas */
@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }

  .filter-group {
    width: 100%;
  }
}
.product-count {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  color: #333;
}
</style>
