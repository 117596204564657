import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import product from "./modules/product";
import cart from "./modules/cart";
import carrusel from "./modules/carrusel";
import config from "./modules/config";

export default createStore({
  modules: {
    product,
    cart,
    carrusel,
    config,
  },
  pluigins: [
    new VuexPersistence({
      storage: window.localStorage
    }).plugin
  ]
});
