<template>
  <v-snackbar
    rounded
    :color="snackColor"
    v-model="showResult"
    :timeout="2000"
    top
  >
    {{ result }}
  </v-snackbar>
  <nav class="navbar">
    <div class="burger-menu">
    </div>
    <div class="brand">
      <router-link to="/">
        <img class="logo" src="@/assets/jin.png" alt="Logo JIN" />
      </router-link>
    </div>
    <div class="tools">
      <div class="dropdown">
        <button
          class="btn dropdown-toggle cart"
          type="button"
          id="dropdownCart"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="fa-solid fa-cart-shopping"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-end cart"
          aria-labelledby="dropdownCart"
        >
          <div class="cartHeader">
            <div class="cartQuantity">
              <i class="fa fa-shopping-cart cart-icon"></i
              ><span class="badge">{{ this.cartQuantity }}</span>
            </div>

            <div class="shopping-cart-total">
              <h6 class="total">Total:</h6>
              <h6 class="totalAmount">{{ this.cartTotal }}€</h6>
            </div>
          </div>
          <hr />
          <div class="products" v-if="this.cartItems.length > 0">
            <div
              class="product"
              v-for="product in this.cartItems"
              :key="product.id"
            >
              <div class="productImage">
                <img
                  class="image"
                  :src="
                    this.baseBackUrl +
                    product.attributes.avatar.data.attributes.url
                  "
                  alt=""
                />
              </div>
              <div class="productDesc">
                <h5 class="productName">{{ product.attributes.nombre }}</h5>
                <div
                  class="info"
                  v-if="!product.attributes.medicion.includes('A consultar')"
                >
                  <h4 class="price">
                    {{ product.attributes.precio * product.cantidad }}€ ({{
                      product.attributes.precio.toFixed(2) +
                      "" +
                      product.attributes.medicion
                    }})
                  </h4>
                  <h4 class="qty">
                    <span v-if="product.attributes.medicion.includes('unidad')"
                      >Unidades:
                    </span>
                    <span v-if="product.attributes.medicion.includes('kilo')"
                      >Kilos:
                    </span>
                    <span v-if="product.attributes.medicion.includes('metro')"
                      >Metros:
                    </span>
                    {{ product.cantidad }}
                  </h4>
                </div>
                <div class="info" v-else>
                  <h4 class="price">{{ product.attributes.medicion }}</h4>
                </div>
                <div
                  v-if="!product.attributes.medicion.includes('A consultar')"
                >
                  <i
                    class="fa-solid fa-plus"
                    style="margin-right: 0.5rem"
                    @click="addToCart(product, 1)"
                  ></i>
                  <i
                    class="fa-solid fa-minus"
                    style="margin-right: 0.5rem"
                    @click="addToCart(product, -1)"
                  ></i>
                  <i class="fa-solid fa-trash" @click="removeFromCart(product)"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="products" v-else>
            <span>Carrito vacío</span>
          </div>
          <hr />
          <div class="checkoutSection">
            <router-link class="checkout" to="/checkout"
              >Ver Carrito
            </router-link>
            <router-link class="empty" :to="this.$route.path" @click="emptyCart"
              >Vaciar Carrito
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from 'jquery'
export default {
  data() {
    return {
      searchText: "",
      snackColor: "error",
      showResult: false,
      result: "",
      searchItems: [],
    };
  },
  mounted() {
    $('div.dropdown-menu').on('click', function(event){
      event.stopPropagation();
    });
  },  
  computed: {
    ...mapGetters(["cartQuantity", "cartTotal", "cartItems", "productItems", "baseBackUrl"]),
  },
  methods: {
    ...mapActions(["addCartItem", "removeCartItem", "removeAllCartItems"]),
    addToCart(prod, ud) {
      this.addCartItem({ prod: prod, cantidad: ud });
      if (ud < 0) {
        this.result =
          "Eliminada una unidad del producto: " + prod.attributes.nombre;
        this.snackColor = "error";
        this.showResult = true;
      } else if (ud > 0) {
        this.result =
          "Añadida una unidad del producto: " + prod.attributes.nombre;
        this.snackColor = "success";
        this.showResult = true;
      }
    },
    removeFromCart(prod) {
      this.removeCartItem(prod);
      this.result = "Se ha eliminado el producto: " + prod.attributes.nombre;
      this.snackColor = "error";
      this.showResult = true;
    },
    emptyCart() {
      this.removeAllCartItems();
      this.result = "Se ha vaciado el carro de compra";
      this.snackColor = "error";
      this.showResult = true;
    },
  },
};
</script>

<style scoped>
.userLinks {
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: var(--grey);
  width: 100%;
  padding: 0.5rem 2rem;
  gap: 0.7rem;
  height: 2rem;
}
.userLinks .link {
  color: var(--carbon);
  text-decoration: none;
  transition: 0.2s;
}
.userLinks .link:hover {
  color: var(--carbonLight);
}
.navbar {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.5rem;
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 2;
}

.dropdown-menu {
  padding: 1rem 1rem 0 1rem;
}
.cart,
.product {
  z-index: 999;
}

.logo {
  width: 80px;
  margin: 0;
  transition: 1s;
}

.logo:hover {
  transform: scale(1.05);
  transition: 0.4s;
}

.tools {
  display: flex;
  align-items: center;
}

.fa-solid {
  color: var(--carbon);
  font-size: 1.5rem;
  transition: 0.6s;
}

.fa-solid:hover {
  color: #004283;
  transition: 0.4s;
}
.dropdown {
  background-color: transparent;
}
.dropdown-toggle::after {
  content: none;
}
.cartHeader {
  display: flex;
  gap: 5rem;
  align-items: center;
}
.cartQuantity {
  display: flex;
  align-items: center;
}
.cart-icon {
  font-size: 1.2rem;
  margin-right: 7px;
}
.badge {
  background-color: var(--blue);
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  padding: 3px 7px;
}
.shopping-cart-total {
  display: flex;
  align-items: center;
  width: 12rem;
}

.total {
  margin: 0;
  font-size: 0.8rem;
}
.totalAmount {
  margin: 0 3px 0 0;
  color: var(--blue);
}
.products {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 10rem;
  overflow-y: auto;
}
.product {
  display: flex;
  margin-right: 0.51rem;
  border-bottom: white 8px groove;
  padding: 0.5rem;
}
.productImage {
  width: 25%;
}
.image {
  width: 60px;
}
.productDesc {
  width: 65%;
}
.info {
  display: flex;
  justify-content: space-between;
}
.info h4 {
  font-size: 0.9rem;
  font-weight: 300;
}
.price {
  color: var(--blue);
}
.qty span {
  font-size: 0.7rem;
  color: var(--carbon);
}
.qty {
  color: var(--green);
}
.go-to-cart {
  display: flex;
  justify-content: right;
}
.see {
  font-size: 0.8rem;
}
.checkoutSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}
.checkout {
  color: white;
  text-decoration: none;
  background-color: var(--blue);
  padding: 5px 15px;
  border-radius: 2rem;
  transition: 0.3s;
}
.empty {
  color: white;
  text-decoration: none;
  background-color: red;
  padding: 5px 15px;
  border-radius: 2rem;
  transition: 0.3s;
}
.checkout:hover {
  background-color: var(--carbonLight);
}
@media screen and (max-width: 900px) {
  .logo {
    width: 60px;
  }
  .fa-solid {
    font-size: 1rem;
  }
  .navbar {
    padding: 0.4rem 1rem;
  }

  .cart-icon {
    font-size: 0.8rem;
    margin-right: 2px;
  }
  .badge {
    background-color: var(--blue);
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    font-size: 9px;
    line-height: 1;
    padding: 3px 7px;
  }
}
@media screen and (max-width: 500px) {
  .cartHeader {
    justify-content: space-between;
  }
  .product {
    width: 90%;
  }
  .productName {
    font-size: 0.9rem;
  }
  .productImage {
    width: 30%;
  }
}
</style>
