<template>
  <div class="main-container" v-if="this.id != 0 && this.product != undefined">
    <v-snackbar
      rounded
      :color="snackColor"
      v-model="showResult"
      :timeout="2000"
      top
    >
      {{ result }}
    </v-snackbar>
    <div class="main-container__box-one">
      <v-carousel progress="#004283" hide-delimiters cycle>
        <template v-slot:prev="{ props }">
          <v-btn icon variant="elevated" color="info" @click="props.onClick">{{
            this.prev
          }}</v-btn>
        </template>
        <template v-slot:next="{ props }">
          <v-btn icon variant="elevated" color="info" @click="props.onClick">{{
            this.next
          }}</v-btn>
        </template>
        <v-carousel-item
          class="main-container__box-one-top"
          v-for="(img, index) in this.product.attributes.imageList.data"
          :key="index"
          :src="this.baseBackUrl + img.attributes.url"
          cover
        ></v-carousel-item>
      </v-carousel>
    </div>
    <div class="main-container__box-two">
      <div class="main-container__box-two--title">
        <h1>{{ this.product.attributes.nombre }}</h1>
        <p class="author">
          <span class="author-span"></span> {{ this.author }}
        </p>
      </div>
      <div class="sub-container__box-two">
        <div
          class="sub-container__box-two--price"
          v-if="!product.attributes.medicion.includes('A consultar')"
        >
          <v-row>
            <v-col cols="12">
              <div class="dollars">
                {{
                  this.product.attributes.precio.toFixed(2) +
                  "" +
                  this.product.attributes.medicion
                }}
              </div>
            </v-col>
            <v-col cols="12">
              <div>
                <h3>Cantidad</h3>
                <v-text-field
                  type="number"
                  step="any"
                  min="1"
                  ref="input"
                  :rules="[numberRule]"
                  v-model.number="number"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="sub-container__box-two--price" v-else>
          <div class="dollars">
            {{ this.product.attributes.medicion }}
          </div>
        </div>
        <p class="desc">
          {{ this.product.attributes.descripcion }}
        </p>
        <div
          class="sub-container__box-two--price"
          v-if="!product.attributes.medicion.includes('A consultar')"
        >
          <v-btn
            class="filled"
            color="blue"
            @click="addToCart(this.product, this.number)"
            >Añadir al Carrito</v-btn
          >
        </div>
        <form action="" id="miForm" class="deliveryInfo" v-else>
          <v-text-field
            filled
            rounded
            dense
            type="text"
            width="100%"
            persistent-hint
            label="Email"
            v-model="this.sendingInfo.email"
            :rules="[required, validateEmail]"
          />
          <v-textarea
            filled
            rounded
            dense
            label="Consulta"
            v-model="this.sendingInfo.info"
            :rules="[required]"
          />
          <v-btn class="filled" color="blue" @click="askForPrice()" :loading="load"
            >Solicitar presupuesto</v-btn
          >
        </form>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({ productItemById: "productItemById", baseBackUrl: "baseBackUrl" }),
    product: function () {
      return this.productItemById(this.id);
    },
  },
  data: function () {
    return {
      numberRule: (val) => {
        if (val < 1) return "La cantidad debe ser mayor que 0";
        return true;
      },
      required: (value) => !!value || value == "" || "Campo requerido.",
      validateEmail: (value) =>
        value.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) != null || "Email inválido",
      sendingInfo: {
        from: "servicio.tecnico@upintelligence.es",
        email: "",
        info: "",
      },
      number: 1,
      id: parseInt(this.$route.params.id),
      prev: "<",
      next: ">",
      author: "Juntas Industriales y Navales",
      snackColor: "error",
      showResult: false,
      result: "",
      load: false,
    };
  },
  methods: {
    ...mapActions([
      "addCartItem",
      "removeCartItem",
      "removeAllCartItems",
      "consulting",
    ]),
    addToCart(prod, ud) {
      this.addCartItem({ prod: prod, cantidad: ud });
      this.result = prod.attributes.nombre + " ha sido añadido al carro. Cantidad añadida: " + ud;
      this.snackColor = "success";
      this.showResult = true
    },
    async askForPrice() {
      this.load = true
      if (
        this.sendingInfo.email == undefined ||
        /^\s*$/.test(this.sendingInfo.email)
      ) {
        this.result = "Campo 'Email' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingInfo.email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) == null
      ) {
        this.result = "Correo inválido";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingInfo.info == undefined ||
        /^\s*$/.test(this.sendingInfo.info)
      ) {
        this.result = "Campo 'Consulta' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else {
        var response = await this.consulting(this.sendingInfo);
        if (response.data.sent) {
          this.load = false
          this.limpiarFormulario();
          this.initSendingInfo();
          this.result = "Pedido realizado";
          this.snackColor = "success";
        } else {
          this.result = "Solicitud fallida";
          this.snackColor = "error";
          this.showResult = true;
        }
      }
      this.load = false
    },
    initSendingInfo() {
      this.sendingInfo = {
        email: "",
        from: "servicio.tecnico@upintelligence.es",
        info: "",
      };
    },
    limpiarFormulario() {
      document.getElementById("miForm").reset();
    },
  },
};
</script>
<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.container {
  background: #fff;
  font-family: "Montserrat", sans-serif;
  max-width: 1200px;
  margin: 0 auto;
}
.main-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 2.5em;
  margin-top: 2.5em;
}
.main-container__box-one {
  width: 100%;
  height: 100%;
}
.main-container__box-one-top {
  background: #f1f0f0;
  border-radius: 10px;
  overflow: hidden;
  padding: 0.5rem;
  height: fit-content;
  width: fit-content;
}
.main-container__box-one-top img {
  width: 100%;
  height: 100%;
}
.main-container__box-one-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}
.main-container__box-one-bottom--innerbox img {
  width: 100%;
  height: 100%;
}
.main-container__box-one-bottom--innerbox:nth-of-type(2) {
  border: 2px solid #004283;
}
.main-container__box-one-bottom div {
  background: #f1f0f0;
  height: 130px;
  width: 150px;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
}
.main-container__box-two {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  margin-left: 2rem;
}
.main-container__box-two--title h1 {
  font-size: 2.2rem;
}
.author {
  margin: 1rem 0;
  color: #004283;
  font-weight: 700;
  font-size: 0.8rem;
  letter-spacing: 2px;
}
.author-span {
  opacity: 0.7;
  color: rgb(184, 183, 183);
}
.sub-container__box-two--price {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  margin: 1rem 0;
}
.dollars {
  background-color: #e5e0ed;
  color: #004283;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 0.5rem 0.8rem;
  border-radius: 7px;
  height: 100%;
}
.percent h3 {
  font-size: 1.1rem;
  color: #004283;
  font-weight: 700;
}
.percent p {
  font-size: 0.9rem;
  color: #919193;
  font-weight: 400;
  margin: 0.5rem 0;
}
.desc {
  color: #919193;
  padding: 1rem 0;
  font-weight: 400;
  line-height: 2;
}
.filled {
  background-color: #004283;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  border: 1px solid #004283;
}
.filled:hover {
  background-color: #1f74ca;
}

@media screen and (max-width: 600px) {
  .main-container {
    display: list-item;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;
  }
  .main-container__box-one {
    width: 100%;
    height: 100%;
    margin-bottom: 2.5em;
  }
  .main-container__box-two {
    width: 80%;
    height: 100%;
  }
}
</style>
