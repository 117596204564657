<template>
  <section class="aboutUs">
    <h1 class="title">Us</h1>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title {
  color: var(--carbon);
}
</style>
