<template>
  <div
    id="landpageCarousel"
    class="carousel slide"
    data-bs-ride="carousel"
    data-interval="0" v-if="this.carruselItems.length > 0"
  >
    <!-- Indicators/dots -->
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#landpageCarousel"
        data-bs-slide-to="0"
        class="active" v-if="this.carruselItems.length > 0"
      ></button>
      <button
        type="button"
        data-bs-target="#landpageCarousel"
        data-bs-slide-to="1" v-if="this.carruselItems.length > 1"
      ></button>
      <button
        type="button"
        data-bs-target="#landpageCarousel"
        data-bs-slide-to="2" v-if="this.carruselItems.length > 2"
      ></button>
    </div>

    <!-- The slideshow/carousel -->
    <div class="carousel-inner">
      <div class="carousel-item active"  v-if="this.carruselItems.length > 0">
        <div class="landing" @click="goToLocation(this.carruselItems[0].attributes.url)">
          <div class="land-img">
            <img
              class="image"
              :src="this.baseBackUrl + this.carruselItems[0].attributes.image.data.attributes.url"
              :alt="this.carruselItems[0].attributes.altText"
            />
          </div>
          <div class="land-msg">
            <p style="color:#fff">{{ this.carruselItems[0].attributes.descripcion }}</p>
          </div>
        </div>
      </div>
      <div class="carousel-item" v-if="this.carruselItems.length > 1">
        <div class="landing" @click="goToLocation(this.carruselItems[1].attributes.url)">
          <div class="land-img">
            <img
              class="image"
              :src="this.baseBackUrl + this.carruselItems[1].attributes.image.data.attributes.url"
              :alt="this.carruselItems[1].attributes.altText"
            />
          </div>
          <div class="land-msg">
            <p style="color:#fff">{{ this.carruselItems[2].attributes.descripcion }}</p>
          </div>
        </div>
      </div>
      <div class="carousel-item" v-if="this.carruselItems.length > 2">
        <div class="landing" @click="goToLocation(this.carruselItems[2].attributes.url)">
          <div class="land-img">
            <img
              class="image"
              :src="this.baseBackUrl + this.carruselItems[2].attributes.image.data.attributes.url"
              :alt="this.carruselItems[2].attributes.altText"
            />
          </div>
          <div class="land-msg">
            <p style="color:#fff">{{ this.carruselItems[2].attributes.descripcion }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**eslint-disable */
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["carruselItems","baseBackUrl"]),
  },
  methods: {
    goToLocation(url){
      window.location = url
    }
  },
};
</script>

<style scoped>
.landing {
  display: flex;
  background-color: var(--black);
  border-radius: 2rem;
  margin: 1rem;
  height: 400px;
  padding: 1rem 3rem;
}
.land-msg {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 0.5rem;
  padding: 2rem;
}
.upper-msg {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.msg {
  text-align: center;
}
.features {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 1rem;
}

.M1 {
  width: 50px;
  -webkit-box-shadow: 0px 0px 20px 6px rgba(255, 46, 116, 0.68);
  -moz-box-shadow: 0px 0px 20px 6px rgba(255, 46, 116, 0.68);
  box-shadow: 0px 0px 20px 6px rgba(255, 46, 116, 0.68);
}

.links {
  display: flex;
  gap: 2rem;
}

a {
  color: var(--blue);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.upper-msg > h3 {
  font-style: italic;
}
.supercharged {
  color: var(--pink);
}
.assistants {
  color: var(--blue);
}

.buy-options {
  display: flex;
  gap: 5rem;
  margin-top: 1rem;
}
.brand {
  display: flex;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
}
.logo {
  width: 50px;
  margin: 1rem;
}
.land-img {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  /* background-color: var(--black); */
}
.landing img.image{
  object-fit: contain;
}
.image {
  width: 100%;
  border-radius: 2rem;
  max-height: 370px;
}
video {
  width: 100%;
  height: auto;
  border-radius: 2rem;
  max-height: 500px;
}
@media screen and (max-width: 900px) {
  .landing {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
  .landing img.image{
  object-fit: fill;
}
  .land-msg {
    display: none;
  }
}
</style>
