const state = {
  baseBackUrl: 'https://ecommerce-jin.upintelligence.es',
  baseFrontUrl: 'http://localhost:8080'
};
const getters = {
  baseBackUrl: (state) => state.baseBackUrl,
  baseFrontUrl: (state) => state.baseFrontUrl,
};
const baseUrlModule = {
  state,
  getters,
};

export default baseUrlModule;
