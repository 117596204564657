<template>
  <section class="checkoutScreen">
    <v-snackbar
      rounded
      :color="snackColor"
      v-model="showResult"
      :timeout="2000"
      top
    >
      {{ result }}
    </v-snackbar>
    <div class="checkOutCard">
      <!-- Billing section -->
      <div class="billing">
        <h3 class="title"><i class="fa-solid fa-lock"></i>Datos de Envío</h3>
        <hr />
        <div class="sections">
          <div class="section">
            <Transition name="slide-fade">
              <div v-show="!isShown" class="content">
                <form action="" id="miForm" class="deliveryInfo">
                  <v-text-field
                    filled
                    rounded
                    dense
                    type="text"
                    persistent-hint
                    class="email"
                    label="Email"
                    v-model="this.sendingData.email"
                    :rules="[rules.required, rules.validateEmail]"
                  />
                  <v-text-field
                    filled
                    rounded
                    dense
                    type="text"
                    label="Empresa"
                    v-model="this.sendingData.empresa"
                    :rules="[rules.required]"
                  />
                  <div class="name">
                    <v-text-field
                      filled
                      rounded
                      dense
                      type="text"
                      label="Nombre"
                      v-model="this.sendingData.nombre"
                      :rules="[rules.required]"
                    />
                    <v-text-field
                      filled
                      rounded
                      dense
                      type="text"
                      label="Apellidos"
                      v-model="this.sendingData.apellidos"
                      :rules="[rules.required]"
                    />
                  </div>
                  <v-text-field
                    filled
                    rounded
                    dense
                    type="text"
                    label="Dirección"
                    v-model="this.sendingData.direccion"
                    :rules="[rules.required]"
                  />
                  <div class="location">
                    <v-text-field
                      filled
                      rounded
                      dense
                      type="text"
                      label="Código Postal"
                      v-model="this.sendingData.cod_postal"
                      :rules="[rules.required]"
                    />
                    <v-text-field
                      filled
                      rounded
                      dense
                      type="text"
                      label="Ciudad"
                      v-model="this.sendingData.ciudad"
                      :rules="[rules.required]"
                    />
                    <v-text-field
                      filled
                      rounded
                      dense
                      type="text"
                      label="Provincia"
                      v-model="this.sendingData.provincia"
                      :rules="[rules.required]"
                    />
                    <v-text-field
                      filled
                      rounded
                      dense
                      type="text"
                      label="País"
                      v-model="this.sendingData.pais"
                      :rules="[rules.required]"
                    />
                  </div>
                </form>
              </div>
            </Transition>
          </div>
          <hr />
          <div class="section">
            <p class="disclaimer">
              Realizando el pedido, usted acepta los
              <a href="">Términos y Condiciones</a> de JIN.
            </p>
          </div>
        </div>
      </div>
      <!-- Checkout section -->
      <div class="check">
        <h3 class="title">
          <i class="fa-solid fa-cart-shopping"></i>Su pedido
        </h3>
        <hr />
        <div class="products" v-if="this.cartItems.length > 0">
          <div
            class="product"
            v-for="product in this.cartItems"
            :key="product.id"
          >
            <div class="productImage">
              <img
                class="image"
                :src="
                  this.baseBackUrl +
                  product.attributes.avatar.data.attributes.url
                "
                alt=""
              />
            </div>
            <div class="productDesc">
              <h5 class="productName">{{ product.attributes.nombre }}</h5>
              <div
                class="info"
                v-if="!product.attributes.medicion.includes('A consultar')"
              >
                <h4 class="price">
                  {{ product.attributes.precio * product.cantidad }}€ ({{
                    product.attributes.precio.toFixed(2) +
                    "" +
                    product.attributes.medicion
                  }})
                </h4>
                <h4 class="qty">
                  <span>Unidades: </span> {{ product.cantidad }}
                </h4>
              </div>
              <div class="info" v-else>
                <h4 class="price">{{ product.attributes.medicion }}</h4>
              </div>
              <div v-if="!product.attributes.medicion.includes('A consultar')">
                <i
                  class="fa fa-plus"
                  style="margin-right: 0.5rem"
                  @click="addToCart(product, 1)"
                ></i>
                <i
                  class="fa fa-minus"
                  style="margin-right: 0.5rem"
                  @click="addToCart(product, -1)"
                ></i>
                <i class="fa fa-trash" @click="removeFromCart(product)"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="products" v-else>
          <span>Vacío</span>
          <router-link class="link" to="/">Ver Productos</router-link>
        </div>
        <hr />
        <v-row>
          <v-col cols="12"> Total (IVA incluido): {{ this.cartTotal }}€ </v-col>
          <v-col cols="12">
            <v-btn
              color="blue"
              @click="this.mailer()"
              :disabled="this.cartItems.length == 0"
              :loading="load"
              >Confirmar pedido</v-btn
            >
          </v-col>
          <v-col cols="12">
            <v-btn
              color="red"
              @click="this.emptyCart"
              :disabled="this.cartItems.length == 0"
              >Vaciar cesta</v-btn
            >
          </v-col>
        </v-row>
        <hr />
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || value == "" || "Campo requerido.",
        validateEmail: (value) =>
          value.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) != null || "Email inválido",
      },
      isShown: false,
      sendingData: {},
      snackColor: "error",
      showResult: false,
      result: "",
      load: false,
    };
  },
  computed: {
    ...mapGetters(["cartQuantity", "cartTotal", "cartItems", "productItems","baseBackUrl"]),
  },
  methods: {
    ...mapActions([
      "addCartItem",
      "removeCartItem",
      "removeAllCartItems",
      "sendEmail",
    ]),
    async mailer() {
      this.load = true;
      if (
        this.sendingData.email == undefined ||
        /^\s*$/.test(this.sendingData.email)
      ) {
        this.result = "Campo 'Email' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingData.email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) == null
      ) {
        this.result = "Correo inválido";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingData.empresa == undefined ||
        /^\s*$/.test(this.sendingData.empresa)
      ) {
        this.result = "Campo 'Empresa' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingData.nombre == undefined ||
        /^\s*$/.test(this.sendingData.nombre)
      ) {
        this.result = "Campo 'Nombre' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingData.apellidos == undefined ||
        /^\s*$/.test(this.sendingData.apellidos)
      ) {
        this.result = "Campo 'Apellidos' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingData.direccion == undefined ||
        /^\s*$/.test(this.sendingData.direccion)
      ) {
        this.result = "Campo 'Dirección' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingData.cod_postal == undefined ||
        /^\s*$/.test(this.sendingData.cod_postal)
      ) {
        this.result = "Campo 'Código Postal' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingData.ciudad == undefined ||
        /^\s*$/.test(this.sendingData.ciudad)
      ) {
        this.result = "Campo 'Ciudad' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingData.provincia == undefined ||
        /^\s*$/.test(this.sendingData.provincia)
      ) {
        this.result = "Campo 'Provincia' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else if (
        this.sendingData.pais == undefined ||
        /^\s*$/.test(this.sendingData.pais)
      ) {
        this.result = "Campo 'Pais' vacío";
        this.snackColor = "error";
        this.showResult = true;
      } else {
        var response = await this.sendEmail(this.sendingData);
        if (this.cartItems.length == 0) {
          this.limpiarFormulario();
          this.initSendingData();
          this.result = "Pedido realizado";
          this.snackColor = "success";
          this.showResult = true;
        } else {
          this.result = "Pedido fallido";
          this.snackColor = "error";
          this.showResult = true;
        }
      }
      this.load = false;
    },
    initSendingData() {
      this.sendingData = {
        id: Math.floor(Math.random() * 999999),
        email: "",
        from: "servicio.tecnico@upintelligence.es",
        empresa: "",
        nombre: "",
        apellidos: "",
        direccion: "",
        cod_postal: "",
        ciudad: "",
        provincia: "",
        pais: "",
        carro: this.cartItems,
        cantidad: this.cartQuantity,
        total: this.cartTotal,
      };
    },
    limpiarFormulario() {
      document.getElementById("miForm").reset();
    },
    addToCart(prod, ud) {
      this.addCartItem({ prod: prod, cantidad: ud });
      if (ud < 0) {
        this.result =
          "Eliminada una unidad del producto: " + prod.attributes.nombre;
        this.snackColor = "error";
        this.showResult = true;
      } else if (ud > 0) {
        this.result =
          "Añadida una unidad del producto: " + prod.attributes.nombre;
        this.snackColor = "success";
        this.showResult = true;
      }
    },
    removeFromCart(prod) {
      this.removeCartItem(prod);
      this.result = "Se ha eliminado el producto: " + prod.attributes.nombre;
      this.snackColor = "error";
      this.showResult = true;
    },
    emptyCart() {
      this.removeAllCartItems();
      this.result = "Se ha vaciado el carro de compra";
      this.snackColor = "error";
      this.showResult = true;
    },
  },
  mounted() {
    this.initSendingData();
  },
};
</script>

<style scoped>
.link {
  text-decoration: none;
  font-size: 0.9rem;
  transition: 0.4s;
  color: #004283;
  font-weight: 300;
  margin: 0;
}

.link:hover {
  color: #1f74ca;
  text-decoration: none;
  transition: 0.4s;
}
.checkoutScreen {
  background-color: rgb(255, 255, 255);
}
.products {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 25rem;
  overflow-y: auto;
}
.product {
  display: flex;
  margin-right: 0.51rem;
}
.productImage {
  width: 20%;
}
.image {
  width: 60px;
}
.productDesc {
  width: 80%;
}
.info {
  display: flex;
  justify-content: space-between;
}
.info h4 {
  font-size: 0.9rem;
  font-weight: 300;
}
.price {
  color: var(--blue);
}
.qty span {
  font-size: 0.7rem;
  color: var(--carbon);
}
.qty {
  color: var(--green);
}
section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}
.checkOutCard {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}
.billing {
  width: 60%;
  padding: 0 0.5rem;
}
.title {
  font-size: 1rem;
  color: var(--carbon);
  display: flex;
  gap: 0.4rem;
}
.subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem;
  cursor: pointer;
  transition: 0.4s;
  padding: 0.3rem 0.5rem;
}
.subtitle:hover {
  background-color: var(--grey);
}
.subtitle div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
input {
  width: 100%;
  margin: 0.8rem 0;
  font-size: 1.2rem;
  outline: none;
  font-size: Roboto;
  font-weight: 400;
  padding: 0 5px;
}

input::placeholder {
  color: var(--carbonLight);
  font-size: 1rem;
  font-weight: 300;
  padding: 0 0.5rem;
}

.name {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
}
.location {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1rem;
}
.confirm_pedido {
  display: flex;
  align-items: center;
  justify-content: center;
}
.deliveryMethods {
  display: flex;
  flex-direction: column;
}
.method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}
input[type="radio"] {
  width: 1rem;
}
.method h5 {
  font-size: 0.8rem;
  margin: 0;
}
.method h6 {
  font-weight: 300;
  margin: 0;
  padding: 0.5rem 0;
}

.disclaimer {
  text-align: center;
  color: var(--carbonLight);
  font-size: 0.9rem;
  font-weight: 300;
}
.cardSection {
  display: flex;
  justify-content: center;
  height: 70%;
}
.paymentOptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.payMethods {
  font-size: 0.8rem;
  margin: 0;
  font-weight: 300;
}
.payments {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.payMethod {
  width: 2rem;
}

.card {
  background: linear-gradient(
    90deg,
    rgb(36, 36, 41) 0%,
    rgb(68, 68, 106) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  width: 52%;
  height: 16vw;
  border: none;
  border-radius: 1rem;
}

.cardNumber {
  position: absolute;
  font-size: 1.6vw;
  bottom: 20%;
  left: 1rem;
  color: white;
}
.cardHolder {
  position: absolute;
  bottom: 5%;
  left: 1rem;
  font-weight: 300;
  font-size: 1vw;
  color: white;
  text-transform: uppercase;
}
.cardDate {
  position: absolute;
  bottom: 5%;
  left: 52%;
  font-weight: 300;
  font-size: 1vw;
  color: white;
}
.cardVendor {
  width: 4.5vw;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 1rem;
}

.cardDetails {
  display: grid;
  grid-template-columns: 1.6fr 0.7fr 0.7fr;
  grid-template-rows: 1fr;
  gap: 0px 8px;
  grid-template-areas: ". . .";
}
.cardDetails input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.continue {
  border: none;
  background: var(--carbon);
  color: white;
  padding: 0.5rem 1rem;
  width: 8rem;
  height: 3rem;
  border-radius: 2rem;
}

.paypal {
  background-color: #fdc33a;
}
.paypalImg {
  height: 2rem;
}
.check {
  width: 40%;
}

/* Transition config */
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media screen and (max-width: 600px) {
  .billing {
    padding: 0;
    width: 100%;
  }
  .productImage {
    width: 30%;
  }
  .productDesc{
    width: 70%;
  }
  .checkOutCard {
    display: list-item;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
  }
  input {
    padding: 0 3px;
    margin: 0.4rem 0;
  }
  input::placeholder {
    font-size: 0.8rem;
    padding: 0 3px;
  }
  .method {
    gap: 0.5rem;
  }
  .method h5 {
    font-size: 0.6rem;
    padding: 0.3rem;
  }
  .method h6 {
    font-size: 0.8rem;
  }
  .disclaimer {
    font-size: 0.7rem;
  }
  .card {
    display: none;
  }
  .action {
    flex-direction: column;
    margin-top: 0.5rem;
    gap: 0.2rem;
  }
  .cardDetails {
    gap: 0px 3px;
  }
  .check{
    width: 100%;
  }
}
</style>
