import axios from "axios";
import store from '../config'
const bu = store.state.baseBackUrl
const state = {
  productItems: [],
};
const mutations = {
  UPDATE_PRODUCT_ITEMS(state, payload) {
    state.productItems = payload;
    localStorage.setItem("productItems", JSON.stringify(state.productItems));
  },
  UPDATE_CATEGORIES_ITEMS(state, payload) {
    state.categories = payload;
    localStorage.setItem("categories", JSON.stringify(state.categories));
  },
};
const actions = {
  getProductItems({ commit }) {
    axios.get(bu + '/api/products?populate=*').then((response) => {
      var arr = response.data.data
      commit("UPDATE_PRODUCT_ITEMS", arr);
    });
  },
  getCategories({ commit }) {
    axios.get(bu + '/api/categorias?populate=*').then((response) => {
      var arr = response.data.data
      commit("UPDATE_CATEGORIES_ITEMS", arr);
    });
  },
};
const getters = {
  productItems: (state) => state.productItems,
  categories: (state) => state.categories,
  productItemById: (state) => (id) => {
    return state.productItems.find((productItem) => productItem.id === id);
  },
};
const productModule = {
  state,
  mutations,
  actions,
  getters,
};

export default productModule;
