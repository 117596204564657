import { find, filter } from "lodash";
import store from '../config'
const bu = store.state.baseBackUrl

import axios from "axios";
const state = {
  cartItems: [],
};
const mutations = {
  UPDATE_CART_ITEMS(state, payload) {
    state.cartItems = payload;
    localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
  },
  ADD_CART_ITEM(state, item) {
    const productInCart = find(state.cartItems, { id: item.prod.id });
    if (!productInCart) {
      const copy = Object.assign({}, item.prod);
      copy.cantidad = item.cantidad;
      state.cartItems.push(copy);
    } else {
      productInCart.cantidad += item.cantidad;
      if(productInCart.cantidad <= 0) state.cartItems = filter(state.cartItems, ({ id }) => id !== productInCart.id)
    }
    localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
  },
  DELETE_CART_ITEM(state, item) {
    state.cartItems = filter(state.cartItems, ({ id }) => id !== item.id);
    localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
  },
  GET_CART_ITEMS_FROM_STORE(state) {
    state.cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  },
};
const actions = {
  addCartItem({ commit }, cartItem) {
    commit("ADD_CART_ITEM", cartItem);
  },
  removeCartItem({ commit }, cartItem) {
    commit("DELETE_CART_ITEM", cartItem);
  },
  removeAllCartItems({ commit }) {
    commit("UPDATE_CART_ITEMS", []);
  },
  getCartItems({ commit }) {
    commit("GET_CART_ITEMS_FROM_STORE");
  },
  async sendEmail({ commit }, body) {
    return await axios
      .post(bu + "/api/emails", body)
      .then((response) => {
        if(response.data.sent) commit("UPDATE_CART_ITEMS", []);
      });
  },
  /* eslint-disable */
  async consulting({ commit },body) {
    return await axios.post(bu + "/api/consulting", body)
  },
};
const getters = {
  cartItems: (state) => state.cartItems,
  cartTotal: (state) => {
    return state.cartItems
      .reduce((acc, cartItem) => {
        return cartItem.cantidad * cartItem.attributes.precio + acc;
      }, 0)
      .toFixed(2);
  },
  cartQuantity: (state) => {
    // N unidades
    // return state.cartItems.reduce((acc, cartItem) => {
    //   return cartItem.cantidad + acc;
    // }, 0);
    // N productos distintos añadidos
    return state.cartItems.length
  },
};
const cartModule = {
  state,
  mutations,
  actions,
  getters,
};
export default cartModule;
