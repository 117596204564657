import axios from "axios";
import store from '../config'
const bu = store.state.baseBackUrl
const state = {
  carruselItems: [],
};
const mutations = {
  UPDATE_CARRUSEL_ITEMS(state, payload) {
    state.carruselItems = payload;
    localStorage.setItem("carruselItems", JSON.stringify(state.carruselItems));
  },
};
const actions = {
  getCarruselItems({ commit }) {
    axios.get(bu + '/api/carrusels?populate=*').then((response) => {
      var arr = response.data.data
      commit("UPDATE_CARRUSEL_ITEMS", arr);
    });
  },
};
const getters = {
  carruselItems: (state) => state.carruselItems,
  carruselItemById: (state) => (id) => {
    return state.carruselItems.find((carruselItem) => carruselItem.id === id);
  },
};
const carruselModule = {
  state,
  mutations,
  actions,
  getters,
};

export default carruselModule;
